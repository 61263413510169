import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import '../../dist/css/bolt-css.css';
import Icon from '../assets/icons/visa.inline.svg';
import iconurl from '../assets/icons/skull.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "svg-example"
    }}>{`SVG example`}</h1>
    <Playground __position={0} __code={'() => {\n  return (\n    <div className=\"tw-flex tw-gap-4\">\n      <div>\n        <Icon width=\"60px\" height=\"60px\" />\n      </div>\n      <div>\n        <img className=\"tw-w-16 tw-h-16\" src={iconurl} />\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      Icon,
      iconurl,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        return <div className="tw-flex tw-gap-4">
        <div>
          <Icon width="60px" height="60px" mdxType="Icon" />
        </div>
        <div>
          <img className="tw-w-16 tw-h-16" src={iconurl} />
        </div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      